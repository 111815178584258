import firebase from 'firebase/compat/app';
import 'firebase/compat/database';

const firebaseConfig = {
  apiKey: "AIzaSyAr2myqkFdIq-m2sow7ywbONcc8NJ-HF14",
  authDomain: "aljesr-7408e.firebaseapp.com",
  projectId: "aljesr-7408e",
  storageBucket: "aljesr-7408e.appspot.com",
  messagingSenderId: "933124401560",
  appId: "1:933124401560:web:3522d8a7b6b03b2274a3ed",
};

firebase.initializeApp(firebaseConfig);
// const database = firebase.database();

export default firebase;