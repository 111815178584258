import React, { useRef } from "react";
import * as XLSX from "xlsx";
import Swal from "sweetalert2";
import "firebase/database";
import firebase from "../../Firebase/firebaseConfig";
import MessageForm from "../MessageForm/MessageForm";

const Dashboard = () => {
  const fileInputRef = useRef(null);

  const handleFileUpload = () => {
    const files = fileInputRef.current.files;
    const readers = [];

    for (let i = 0; i < files.length; i++) {
      readers.push(
        new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const worksheet = workbook.Sheets[workbook.SheetNames[1]]; // اختيار الورقة المناسبة
            const jsonData = XLSX.utils.sheet_to_json(worksheet, {
              header: 1,
              range: 4,
            });

            const formattedData = jsonData
              .map((row) => {
                if (row.filter((cell) => cell !== "").length === 0) {
                  return null; // تجاهل السطر الفارغ
                }

                let grade = row[3] !== undefined ? row[3] : "";
                switch (grade) {
                  case "0125":
                    grade = "الأول";
                    break;
                  case "0225":
                    grade = "الثاني";
                    break;
                  case "0325":
                    grade = "الثالث";
                    break;
                  case "0430":
                    grade = "الرابع";
                    break;
                  case "0530":
                    grade = "الخامس";
                    break;
                  case "0630":
                    grade = "السادس";
                    break;
                  default:
                    break;
                }

                return {
                  phoneNumber: row[1] !== undefined ? row[1] : "",
                  classs: row[2] !== undefined ? row[2] : "",
                  grade: grade,
                  studentName: row[4] !== undefined ? row[4] : "",
                  civilID: row[5] !== undefined ? row[5] : "",
                };
              })
              .filter((row) => row !== null); // تجاهل السطر الفارغ

            resolve(formattedData);
          };

          reader.readAsArrayBuffer(files[i]);
        })
      );
    }

    Promise.all(readers)
      .then((results) => {
        const mergedData = results.flat(); // دمج بيانات الملفات المتعددة في مصفوفة واحدة

        const database = firebase.database();

        database
          .ref("students")
          .set(mergedData)
          .then(() => {
            Swal.fire({
              title: "نجاح!",
              text: "تم رفع جميع الملفات بنجاح.",
              icon: "success",
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "حدث خطأ",
              text: "ليس لديك صلاحية،يرجى التواصل مع المطور",
              icon: "error",
            });
          });
      })
      .catch((error) => {
        Swal.fire({
          title: "خطأ!",
          text: `حدث خطأ أثناء قراءة الملفات: ${error}`,
          icon: "error",
        });
      });
  };

  return (
    <>
      <div className="container mt-5 center">
        <div className="row">
          <div className="col">
            <h3 className="mt-5">رفع بيانات الطلاب من نظام نور</h3>
            <input
              className="form-control mt-3"
              type="file"
              ref={fileInputRef}
              accept=".xlsx, .xls"
              required
             multiple
            />
            <button className="btn btn-primary mt-3" onClick={handleFileUpload}>
              رفع البيانات
            </button>
          </div>
        </div>
      </div>
      <MessageForm />
    </>
  );
};

export default Dashboard;