import React, { useState } from "react";
import Swal from "sweetalert2";
import firebase from "../../Firebase/firebaseConfig";

const MessageForm = () => {
  const [messageTitle, setMessageTitle] = useState("");
  const [messageText, setMessageText] = useState("");

  const handleSaveMessage = () => {
    const messagesRef = firebase.database().ref("messages");

    messagesRef
      .push({
        title: messageTitle,
        text: messageText,
      })
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "تم حفظ الرسالة بنجاح!",
          showConfirmButton: false,
          timer: 1500,
        });
        setMessageTitle("");
        setMessageText("");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "حدث خطأ أثناء حفظ الرسالة",
          text: error.message,
        });
      });
  };

  return (
    <div className="container mt-5 center">
      <h2>إضافة رسالة جديدة</h2>
      {/* <form>
        <div className="form-group">
          <label htmlFor="messageTitle">عنوان الرسالة</label>
          <input
            type="text"
            className="form-control"
            id="messageTitle"
            required
            value={messageTitle}
            onChange={(e) => setMessageTitle(e.target.value)}
          />
        </div>

        <div className="form-group mt-3">
          <label htmlFor="messageText">نص الرسالة</label>
          <textarea
            className="form-control"
            id="messageText"
            required
            rows="3"
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
          ></textarea>
        </div>
        <button className="btn btn-primary mt-3" onClick={handleSaveMessage}>
          حفظ الرسالة
        </button>
      </form> */}
      <form>
        <div className="form-group">
          <label htmlFor="messageTitle">عنوان الرسالة</label>
          <input
            type="text"
            className="form-control"
            id="messageTitle"
            required
            value={messageTitle}
            onChange={(e) => setMessageTitle(e.target.value)}
          />
        </div>

        <div className="form-group mt-3">
          <label htmlFor="messageText">نص الرسالة</label>
          <textarea
            className="form-control"
            id="messageText"
            required
            rows="3"
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
          ></textarea>
        </div>

        
      </form>
      <button className="btn btn-primary mt-3" onClick={handleSaveMessage}>
          حفظ الرسالة
        </button>
    </div>
  );
};

export default MessageForm;
