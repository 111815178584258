import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import { HiMenuAlt2 } from "react-icons/hi";
import "./Sidebar.css";

function Sidebar({ authenticated, handleLogout }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  return (
    <div className="sidebar-container">
      {authenticated ? (
        <>
        
        <div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
        <Link
          to="/"
          className={classNames({ active: location.pathname === "/" })}
          activeclassname="active"
          onClick={closeSidebar}
        >
          الرئيسة
        </Link>
        <Link
          to="/dashboard"
          className={classNames({ active: location.pathname === "/dashboard" })}
          activeclassname="active"
          onClick={closeSidebar}
        >
          لوحة التحكم
        </Link>
        <Link
          to="/support"
          className={classNames({ active: location.pathname === "/support" })}
          activeclassname="active"
          onClick={closeSidebar}
        >
          الدعم الفني
        </Link>
        <li className="">
          <a href="/" className="" onClick={handleLogout}>
            خروج
          </a>
        </li>
      </div>
      <button
        className={classNames("toggle-btn", { open: isSidebarOpen })}
        onClick={toggleSidebar}
      >
        <HiMenuAlt2 />
      </button>
        
        </>
      ) : (
        <></>
      )}
      
    </div>
  );
}

export default Sidebar;
