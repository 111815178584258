import React, { useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import Swal from "sweetalert2";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    const auth = getAuth();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      Swal.fire({
        icon: "success",
        text: "تم تسجيل الدخول بنجاح",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        text: "البيانات غير صحيحة",
      });
    }
  };

  return (
    <div className="container" dir="rtl">
      <div className="img mt-5" >
        <img src="../../AljesrIcon.png" width={200} alt="AljesrSchools" />
      </div>
      <h1 className="sub center mt-5 ">تسجيل الدخول</h1>

      <form className="mt-5" dir="ltr" onSubmit={handleLogin}>
        <div className="form-floating mb-3">
          <input
            className="form-control"
            type="email"
            id="username"
            name="username"
            autoComplete="on"
            placeholder="USER NAME"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <label htmlFor="username">اسم المستخدم</label>
        </div>
        <div className="form-floating mb-3">
          <input
            className="form-control"
            type="password"
            id="PASSWORD"
            name="PASSWORD"
            placeholder="PASSWORD"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <label htmlFor="PASSWORD">كلمةالمرور</label>
        </div>
        <button className="btn btn-primary" type="submit">
          تسجيل الدخول
        </button>
      </form>
    </div>
  );
};

export default Login;
