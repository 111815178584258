import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './Auth/AuthContext';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import Login from './components/Login/Login';
import Home from './components/Home/Home';
import Dashboard from './components/Dashboard/Dashboard';
import Sidebar from './components/Sidebar/Sidebar';
import Support from './components/Support/Support';
import './App.css';


function App() {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthenticated(true);
      } else {
        setAuthenticated(false);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        setAuthenticated(false);
      })
      .catch((error) => {
        console.log('حدث خطأ أثناء تسجيل الخروج:', error);
      });
  };
  return (
    <Router>
      <AuthProvider>
        {loading ? (
          <p className='center mt-5'>جارٍ التحميل</p>
         ) : (
          <div>
            <Sidebar authenticated={authenticated} handleLogout={handleLogout} />
            <Routes>
              <Route
                path="/"
                element={authenticated ? <Home /> : <Login />}
              />
              <Route
                path="/dashboard"
                element={authenticated ? <Dashboard /> : <Navigate to="/login" />}
              />
              <Route
                path="/support"
                element={authenticated ? <Support /> : <Navigate to="/login" />}
              />
            </Routes>
          </div>
        )}
      </AuthProvider>
    </Router>
  );
}

export default App;

