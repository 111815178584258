import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import { MdWifiCalling3 } from "react-icons/md";
const Support = () => {
  return (
    <div className="container">
      <div className="img mt-5">
        <img src="../../AljesrIcon.png" width={200} alt="AljesrSchools" />
      </div>
      <h1 className="center mt-5">الدعم الفني</h1>
      <p className="center mt-3">للدعم الفني يُرجى التواصل مع علي الخشرمي</p>
      <div className="center">
        <a href="tel:0583401510" className="btn btn-primary">
          <MdWifiCalling3 />
        </a>
        <a
          href="https://wa.me/966583401510"
          className="btn btn-success"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaWhatsapp />
        </a>
      </div>
    </div>
  );
};

export default Support;
