import React, { useState, useEffect } from "react";
import firebase from "../../Firebase/firebaseConfig";
import Swal from "sweetalert2";
import { RiWhatsappFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import { IoCallSharp } from "react-icons/io5";
import { IoIosInformationCircle } from "react-icons/io";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const Home = () => {
  const [studentsData, setStudentsData] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const [messages, setMessages] = useState([]);
  const [, setSelectedMessage] = useState("");
  const [messageText, setMessageText] = useState("");

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleTextareaChange = (event) => {
    setMessageText(event.target.value);
  };

  useEffect(() => {
    const messagesRef = firebase.database().ref("messages");
    messagesRef.on("value", (snapshot) => {
      const messagesData = snapshot.val();
      const messagesArray = [];
      for (let messageId in messagesData) {
        messagesArray.push({
          id: messageId,
          title: messagesData[messageId].title,
          text: messagesData[messageId].text,
        });
      }
      setMessages(messagesArray);
    });
  }, []);

  const handleSelectMessage = (event) => {
    const selectedId = event.target.value;
    const selectedMessage = messages.find(
      (message) => message.id === selectedId
    );
    setSelectedMessage(selectedId);
    setMessageText(selectedMessage ? selectedMessage.text : "");
  };

  useEffect(() => {
    fetchStudents();
  }, []);

  const fetchStudents = () => {
    const database = firebase.database();

    database
      .ref("students")
      .once("value")
      .then((snapshot) => {
        const fetchedData = snapshot.val();
        if (fetchedData) {
          const formattedData = Object.entries(fetchedData).map(
            ([key, value]) => ({
              id: key,
              ...value,
            })
          );
          setStudentsData(formattedData);
        }
      })
      .catch((error) => {
        console.error("حدث خطأ أثناء جلب البيانات :", error);
      });
  };

  const handleDelete = (studentId) => {
    Swal.fire({
      title: "هل أنت متأكد؟",
      text: "سيتم حذف الطالب نهائيًا!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "نعم، احذفه!",
      cancelButtonText: "إلغاء",
    }).then((result) => {
      if (result.isConfirmed) {
        const database = firebase.database();

        database
          .ref(`students/${studentId}`)
          .remove()
          .then(() => {
            fetchStudents();
            Swal.fire({
              icon: "success",
              text: "تم حذف الطالب بنجاح",
            });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              text: "ليس لديك صلاحية،يرجى التواصل مع المطور",
            });
          });
      }
    });
  };

  const handleEdit = (student) => {
    setSelectedStudent(student);
    handleShow();
  };

  const handleCloseModal = () => {
    setSelectedStudent(null);
    setShowModal(false);
  };

  const handleSaveChanges = () => {
    const database = firebase.database();

    database
      .ref(`students/${selectedStudent.id}`)
      .set(selectedStudent)
      .then(() => {
        fetchStudents();
        Swal.fire({
          icon: "success",
          text: "تم حفظ التغييرات بنجاح",
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text: "ليس لديك صلاحية،يرجى التواصل مع المطور",
        });
      });

    handleCloseModal();
  };

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  };

  const filteredStudents = studentsData.filter((student) => {
    const search = searchInput.toLowerCase();
    return (
      student.studentName.toLowerCase().includes(search) ||
      student.civilID.toLowerCase().includes(search) ||
      student.phoneNumber.toLowerCase().includes(search)
    );
  });

  const countRows = () => {
    return filteredStudents.length;
  };

  const azizText = "عزيزي ولي أمر الطالب:";
  const schoolName = "*المرسل:ابتدائية جسر الملك فهد*";
  const finalMessage = `\n${messageText}\n\n${schoolName}`;

  return (
    <div className="container" dir="rtl">
      <h1 className="center mt-5">ابتدائية جسر الملك فهد</h1>
      <div className="row mb-3">
        <div className="col">
          <p className="center">عدد الطلاب: {countRows()}</p>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col">
          <h5 className="center">قائمة الرسائل</h5>
          <select className="form-control" onChange={handleSelectMessage}>
            <option value="">اختر رسالة</option>
            {messages.map((message) => (
              <option value={message.id} key={message.id}>
                {message.title}
              </option>
            ))}
          </select>
          <textarea
            className="form-control mt-3"
            rows="3"
            placeholder="اختر من قائمة الرسائل أو اكتب رسالة مخصصة"
            value={messageText}
            onChange={handleTextareaChange}
          ></textarea>
        </div>
      </div>
      <div className="form-group mb-3">
        <input
          type="text"
          className="form-control mt-5"
          placeholder="ابحث عن اسم الطالب أو الهوية أو الجوال"
          value={searchInput}
          onChange={handleSearch}
        />
      </div>
      <table className="table  table-hover" dir="rtl">
        <thead>
          <tr>
            <th>#</th>
            <th>اسم الطالب</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th>الصف</th>
            <th>الفصل</th>
            <th>رقم الهاتف</th>
            <th>السجل المدني</th>
          </tr>
        </thead>
        <tbody>
          {filteredStudents.map((student, index) => (
            <React.Fragment key={student.id}>
              <tr>
              <td className="num"><h6>{index + 1}</h6></td>
                <td className="td" colSpan="6">
                  {student.studentName} <br /><br />
                  <button
                    className="btn btn-danger"
                    onClick={() => handleDelete(student.id)}
                  >
                    <MdDelete />
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={() => handleEdit(student)}
                  >
                    <IoIosInformationCircle />
                  </button>
                  <a
                    href={`https://wa.me/${
                      student.phoneNumber
                    }?text=${azizText}\n${
                      student.studentName
                    }\n${encodeURIComponent(finalMessage)}`}
                    className="btn btn-success"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <RiWhatsappFill />
                  </a>
                  <a
                    href={`tel:+${student.phoneNumber}`}
                    className="btn btn-primary"
                  >
                    <IoCallSharp />
                  </a>
                </td>
                <td><h6>{student.grade}</h6></td>
                <td><h6>{student.classs}</h6></td>
                <td><h6>{student.phoneNumber}</h6></td>
                <td><h6>{student.civilID}</h6></td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>

      <Modal show={showModal} onHide={handleClose} dir="rtl">
        <Modal.Header closeButton>
          <Modal.Title>بيانات طالب</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="form-group">
            <label htmlFor="studentName">اسم الطالب</label>
            <input
              type="text"
              className="form-control"
              id="studentName"
              value={selectedStudent?.studentName}
              onChange={(e) =>
                setSelectedStudent({
                  ...selectedStudent,
                  studentName: e.target.value,
                })
              }
            />
          </div>

          <div className="form-group">
            <label htmlFor="grade">الصف</label>
            <input
              type="text"
              className="form-control"
              id="grade"
              value={selectedStudent?.grade}
              onChange={(e) =>
                setSelectedStudent({
                  ...selectedStudent,
                  grade: e.target.value,
                })
              }
            />
          </div>

          <div className="form-group">
            <label htmlFor="classs">الفصل</label>
            <input
              type="text"
              className="form-control"
              id="classs"
              value={selectedStudent?.classs}
              onChange={(e) =>
                setSelectedStudent({
                  ...selectedStudent,
                  classs: e.target.value,
                })
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="phoneNumber">جوال ولي الأمر</label>
            <input
              type="text"
              className="form-control"
              id="phoneNumber"
              value={selectedStudent?.phoneNumber}
              onChange={(e) =>
                setSelectedStudent({
                  ...selectedStudent,
                  phoneNumber: e.target.value,
                })
              }
            />
          </div>

          <div className="form-group">
            <label htmlFor="civilID">الهوية المدنية</label>
            <input
              type="text"
              className="form-control"
              id="civilID"
              value={selectedStudent?.civilID}
              onChange={(e) =>
                setSelectedStudent({
                  ...selectedStudent,
                  civilID: e.target.value,
                })
              }
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSaveChanges}>
            حفظ التغييرات
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            إغلاق
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Home;
